import "./index.scss"
import React, {useEffect, useState} from "react";
import {navigate} from "gatsby";
import {useWindowWidth} from "../../../services/hooks";
import NewButton from "../HeroV3/NewButton";

const keywords = {
  ch: {
    title: "Our price is the only thing “cheap” about these contact lenses.",
    subtitle: "Start now for as little as $1.",
  },
  dis: {
    title: "Order Daily Disposable Contacts Online.",
    subtitle: "Start now for as little as $1.",
  },
  del: {
    title: "Fast Contact Lens Delivery",
    subtitle: "Start now for as little as $1.",
  },
}

const KeywordHero = ({ keyword }) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth <= 768)
  }, [windowWidth])


  const handleClick = () => {
    navigate("#order-section")
  }

  return (
    <section className="keyword-hero" data-keyword={keyword}>
      <div className="content-container-hero">
        <div className="background"/>

        <div className="text-block grid-section">
          <h1 className="title">{keywords[keyword].title}</h1>
          <p className="subtitle">{keywords[keyword].subtitle}</p>
          <NewButton
            label="Start Your Subscription"
            color="purple"
            onClick={handleClick}
            className={`start-button ${isMobile ? "mobile" : ""}`}
            stickyScroll={isMobile}
          />
        </div>
      </div>
    </section>
  )
}

export default KeywordHero
